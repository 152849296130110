import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h>Currently In Development...</h>
        <p>Check out our Etsy in the meantime!</p>
        <a className="App-link" href="https://www.etsy.com/shop/SunshineGoldenAcres?ref=shop-header-name&listing_id=1260635797" target="_blank" rel="noopener noreferrer">Click Here</a>
      </header>
    </div>
  );
}

export default App;
